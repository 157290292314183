import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    LabelCheckResultDetailed,
    CheckResult,
    AssetReviewResult,
    RuleResult,
    Rule,
    CommentActivity,
    StatusChangeActivity,
    UploadActivity,
} from '../types';
import {
    deleteAsset,
    fetchAssetDetails,
    handleLibraryRequest,
    submitFeedbackForItem,
} from '../utils/backendServices';
import Spinner from './Spinner';
import { useLibraryContext } from '../contexts/LibraryContext';
import AssetDetailsBrowser from './AssetDetailsBrowser';
import { AssetContext } from '../contexts/AssetContext';
import ShowcaseModal from './ShowcaseModal';
import { useNavigateWithinOrg } from '../utils/navigation';

const AssetDetails: FunctionComponent = () => {
    const libraryContext = useLibraryContext();

    const { id } = useParams<{ id: string }>();
    const [reviewResult, setReviewResult] = useState<AssetReviewResult | null>(
        null,
    );
    const [activities, setActivities] = useState<
        (StatusChangeActivity | CommentActivity | UploadActivity)[]
    >([]);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [focussedRule, setFocussedRule] = useState<string | null>(null);

    const [error, setError] = useState<string | null>(null);

    const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);

    // const [isModalVisible, setIsModalVisible] = useState<boolean>(true);
    const [isDeleteModalVisible, setIsDeleteModalVisible] =
        useState<boolean>(false);
    const [selectedRule, setSelectedRule] = useState<RuleResult | null>(null);

    useEffect(() => {
        if (id) {
            fetchAssetDetails(id)
                .then((assetDetailResponse) => {
                    setReviewResult(assetDetailResponse.review_result);
                    setActivities(assetDetailResponse.activities);
                    setImageUrl(assetDetailResponse.image_url);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, []);

    const handleRuleSelected = (rule: RuleResult) => {
        setSelectedRule(rule);
    };

    const handleCloseModal = () => {
        setSelectedRule(null);
    };

    const handleSubmitFeedback = async (
        selectedRule: CheckResult,
        newOuctome: string,
        comment: string,
    ) => {
        if (id) {
            setSelectedRule(null);
            setIsSubmittingFeedback(true);
            const response = await submitFeedbackForItem(
                id,
                selectedRule.display_name,
                {
                    comment: comment,
                    new_outcome: newOuctome,
                },
            );
            // TODO: Needs to be fixed
            // setItem(response.data);
            setIsSubmittingFeedback(false);
            handleLibraryRequest(libraryContext, 1, false).catch((error) => {
                console.log(error);
            });
        }
    };

    const handleDeleteButton = () => {
        setIsDeleteModalVisible(true);
    };
    // const handleDeleteConfirmation = () => {
    //     if (labelCheckResult) {
    //         setIsDeleteModalVisible(false);
    //         libraryContext.setters.setLibraryIsLoading(true);
    //         navigate('/library');
    //         deleteAsset(labelCheckResult.id)
    //             .then(() => {
    //                 handleLibraryRequest(libraryContext).catch((error) => {
    //                     console.log(error);
    //                 });
    //             })
    //             .catch((error) => {
    //                 console.error('Error deleting asset:', error);
    //             });
    //     }
    // };

    const closeDeleteModal = () => {
        setIsDeleteModalVisible(false);
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!reviewResult || !imageUrl || isSubmittingFeedback) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spinner title="Loading..." />
            </div>
        );
    }

    return (
        <AssetContext.Provider
            value={{
                state: {
                    reviewResult,
                    imageUrl,
                    activities,
                    focussedRule,
                },
                setters: { setFocussedRule, setActivities },
            }}
        >
            <div className="detail-container">
                <ShowcaseModal
                    isActive={isDeleteModalVisible || selectedRule !== null}
                    onClose={() => {
                        handleCloseModal();
                        closeDeleteModal();
                    }}
                />
                <AssetDetailsBrowser
                    handleDeleteButton={handleDeleteButton}
                    handleRuleSelected={handleRuleSelected}
                />
            </div>
        </AssetContext.Provider>
    );
};

export default AssetDetails;
