import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEllipsis,
    faEllipsisVertical,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import StatusIndicator from './StatusIndicator';
import AssetDetailsBackButton from './AssetDetailsBackButton';
import { useAssetContext } from '../contexts/AssetContext';

interface AssetDetailsTitleBarProps {
    onDelete: () => void;
}

const AssetDetailsTitleBar: React.FC<AssetDetailsTitleBarProps> = ({
    onDelete,
}) => {
    const {
        state: {
            reviewResult: {
                properties: { asset_name },
                approval_status,
            },
        },
    } = useAssetContext();
    return (
        <div className="approval-container">
            <h2>{asset_name}</h2>
            <div className="approval-actions">
                <StatusIndicator
                    decision={approval_status}
                    isBig={true}
                    onEdit={() => console.log('Edit status')}
                />
                <button className="approval-delete" onClick={onDelete}>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </button>
                {/* <AssetDetailsBackButton /> */}
            </div>
        </div>
    );
};

export default AssetDetailsTitleBar;
