import { createContext, useContext } from 'react';
import {
    AssetReviewResult,
    CommentActivity,
    StatusChangeActivity,
    UploadActivity,
} from '../types';

// Your existing state interface
export interface AssetState {
    reviewResult: AssetReviewResult;
    imageUrl: string;
    activities: (StatusChangeActivity | CommentActivity | UploadActivity)[];
    focussedRule: string | null;
}

// New interface for setters
export interface AssetSetters {
    setFocussedRule: React.Dispatch<React.SetStateAction<string | null>>;
    setActivities: React.Dispatch<
        React.SetStateAction<
            (StatusChangeActivity | CommentActivity | UploadActivity)[]
        >
    >;
}

// Update the context to include both state and setters
export interface AssetContextType {
    state: AssetState;
    setters: AssetSetters;
}

export const AssetContext = createContext<AssetContextType | null>(null);

export function useAssetContext() {
    const assetContext = useContext(AssetContext);

    if (assetContext === null) {
        throw new Error('useAssetContext must be used with a AssetContext');
    }

    return assetContext;
}
