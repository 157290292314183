import React, { useState } from 'react';
import { Activity } from '../types';
import { ActivityItem } from './ActivityItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import NewComment from './NewComment';
import { useUser } from '@clerk/clerk-react';
import { postComment } from '../utils/backendServices';
import { useAssetContext } from '../contexts/AssetContext';

interface ActivityFeedProps {
    activities: Activity[];
}

export const ActivityFeed: React.FC<ActivityFeedProps> = ({ activities }) => {
    const [openComment, setOpenComment] = useState<boolean>(false);
    const { user } = useUser();
    const {
        state: {
            reviewResult: { id },
        },
        setters: { setActivities },
    } = useAssetContext();

    const handleSubmit = async (comment: string) => {
        setOpenComment(false);
        const newActivities = await postComment(id, { content: comment });
        setActivities(newActivities);
    };
    if (user) {
        return (
            <>
                <div className="activity-controls">
                    <h3>Activity Feed</h3>
                    {!openComment && (
                        <button
                            className="comment-button"
                            onClick={() => setOpenComment(true)}
                        >
                            Comment
                            <FontAwesomeIcon icon={faComment} />
                        </button>
                    )}
                </div>
                <div className="activity-feed">
                    {openComment && (
                        <NewComment
                            onCancel={() => setOpenComment(false)}
                            onSubmit={handleSubmit}
                            avatarUrl={user.imageUrl}
                        />
                    )}
                    {activities.map((activity, i) => (
                        <ActivityItem
                            key={i}
                            activity={activity}
                            firstActivity={i == activities.length - 1}
                        />
                    ))}
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default ActivityFeed;
