import React, { FunctionComponent, useEffect } from 'react';
import {
    CheckResult,
    RuleOutcome,
    RuleResult,
    SpecCheckOutcome,
} from '../types';
import UserInputTable from './UserInputTable';
import RuleChecksTable from './RulesCheckTable';
import { MacScrollbar } from 'mac-scrollbar';
import styled from 'styled-components';
import { useAssetContext } from '../contexts/AssetContext';
import { formatDate } from '../utils/timeUtils';
import ActivityFeed from './ActivityFeed';

interface AssetDetailsInformationProps {
    handleRuleSelected: (selectedRule: RuleResult) => void;
}
const Scrollbar = styled(MacScrollbar)`
    flex: 1; /* Take up remaining space */
    overflow-y: auto; /* Enable scrolling */
    display: flex;
    flex-direction: column;
`;

const AssetDetailsInformation: FunctionComponent<
    AssetDetailsInformationProps
> = ({ handleRuleSelected }) => {
    const {
        state: { reviewResult, activities },
    } = useAssetContext();

    function getOutcomeOrder(outcome: RuleOutcome) {
        const order = {
            [RuleOutcome.FAIL]: 1,
            [RuleOutcome.WARNING]: 2,
            [RuleOutcome.PASS]: 3,
        };
        return order[outcome] || 4; // default order for unknown outcomes
    }

    const compareRules = (a: RuleResult, b: RuleResult) => {
        // Compare by 'outcome' first
        if (getOutcomeOrder(a.outcome) < getOutcomeOrder(b.outcome)) {
            return -1;
        }
        if (getOutcomeOrder(a.outcome) > getOutcomeOrder(b.outcome)) {
            return 1;
        }
        return 0;
        // return b.conf - a.conf;
    };

    const ruleResultsOrdered = reviewResult.rule_results.sort(compareRules);

    const failedRules = ruleResultsOrdered.filter(
        (rule) =>
            rule.outcome === RuleOutcome.WARNING ||
            rule.outcome === RuleOutcome.FAIL,
    );
    const rulesFlagged = failedRules.length;
    const rulesPassed = ruleResultsOrdered.length - rulesFlagged;
    const totalRules = rulesPassed + rulesFlagged;

    const message =
        rulesFlagged === 0
            ? `has passed all ${totalRules} rules`
            : `failed ${rulesFlagged}/${totalRules} rules`;

    const failedRulesList = failedRules
        .map((rule) => rule.display_name)
        .join(', ');

    // If the asset has passed all rules, exclude the second sentence
    const hasFailedRules = rulesFlagged > 0;
    const additionalMessage = hasFailedRules
        ? ` It has failed the following rules: <b>${failedRulesList}</b>`
        : '';

    return (
        <>
            <hr />
            <Scrollbar
                trackStyle={(horizontal) => ({
                    [horizontal ? 'height' : 'width']: 0,
                    right: 0,
                    border: 0,
                })}
                thumbStyle={(horizontal) => ({
                    [horizontal ? 'height' : 'width']: 6,
                })}
            >
                <p
                    style={{
                        marginBottom: '0px',
                        marginTop: '0px',
                        fontSize: '1.1em',
                        paddingRight: '10px',
                    }}
                >
                    The asset <b>{reviewResult.properties.asset_name}</b> has{' '}
                    <b>{message}</b> for the{' '}
                    <b>{reviewResult.properties.market}</b> market.
                    {hasFailedRules && (
                        <>
                            {' '}
                            It has failed the following rules:{' '}
                            <b>{failedRulesList}</b>
                        </>
                    )}
                </p>
                <hr />
                <RuleChecksTable
                    ruleResults={reviewResult.rule_results}
                    onRuleSelected={handleRuleSelected}
                />
                <hr />
                <ActivityFeed activities={activities} />
                {/* <TextTable extractedText={extracedText} /> */}
                <hr />

                <UserInputTable
                    assetName={reviewResult.properties.asset_name}
                    country={reviewResult.properties.market}
                    user={reviewResult.user_id}
                    uploadTime={formatDate(
                        reviewResult.run_info.timestamp,
                        true,
                    )}
                    id={reviewResult.id.toString()}
                />
                <hr />
            </Scrollbar>
        </>
    );
};

export default AssetDetailsInformation;
function useState<T>(arg0: null): [any, any] {
    throw new Error('Function not implemented.');
}
