import React, { FunctionComponent, useEffect, useState } from 'react';
import UploadBoxAsset from '../components/UploadBoxAsset';
import ModalBase from '../components/ModalBase';
import ShowcaseModal from '../components/ShowcaseModal';
import InputFieldTitle from '../components/InputFieldTitle';
import { SignedIn, SignedOut, SignInButton } from '@clerk/clerk-react';
import {
    AlcoholStrength,
    BelgiumAssetProperties,
    Brand,
    DutchAssetProperties,
    FileFormat,
    FrenchAssetProperties,
    Market,
    YesNo,
} from '../types';
import { useUploadContext } from '../contexts/UploadContext';
import { useNavigate } from 'react-router-dom';
import useHash from '../hooks/useHash';
import { fetchUploadToken, reviewAsset } from '../utils/backendServices';
import axios from 'axios';

interface UserInput {
    assetName: string;
    market: Market;
}

type InputField = {
    label: string;
    fieldName:
        | keyof DutchAssetProperties
        | keyof BelgiumAssetProperties
        | keyof FrenchAssetProperties;
    inputType: 'text' | 'dropdown';
    options?: string[];
    defaultValue?: string;
};

const sharedFields: InputField[] = [
    {
        fieldName: 'asset_name',
        label: 'Asset Name',
        inputType: 'text',
    },
    {
        fieldName: 'brand',
        label: 'Brand',
        inputType: 'dropdown',
        options: Object.values(Brand) as Brand[],
    },
];

const marketSpecificFields: Record<Market, InputField[]> = {
    BeLux: [
        {
            fieldName: 'on_public_roads',
            label: 'On Public Roads',
            inputType: 'dropdown',
            options: Object.values(YesNo) as YesNo[],
            defaultValue: YesNo.YES,
        },
    ],
    Netherlands: [
        {
            fieldName: 'alcohol_strength',
            label: 'Alcohol Strength',
            inputType: 'dropdown',
            options: Object.values(AlcoholStrength) as AlcoholStrength[],
            defaultValue: AlcoholStrength.ABV_OVER_0_5,
        },
    ],
    France: [],
};

type FormData = {
    [key in
        | (typeof sharedFields)[number]['fieldName']
        | (typeof marketSpecificFields)[keyof typeof marketSpecificFields][number]['fieldName']]: string;
};

interface UploadAssetProps {
    market: Market;
}

const UploadAsset: FunctionComponent<UploadAssetProps> = ({ market }) => {
    const [formData, setFormData] = useState<FormData>({} as FormData);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [canSubmit, setCanSubmit] = useState(false);

    const { setHashValue } = useHash();
    const { triggerFetch } = useUploadContext();

    const marketFields = marketSpecificFields[market] || [];
    const combinedFields = [...sharedFields, ...marketFields];

    const initialiseFormData = () => {
        const initialFormData = combinedFields.reduce(
            (acc, field) => ({
                ...acc,
                [field.fieldName]: field.defaultValue ?? '',
            }),
            {},
        ) as FormData;
        setFormData(initialFormData);
    };

    useEffect(() => {
        initialiseFormData();
    }, [market]);

    const handleInputChange = (fieldName: keyof FormData, value: string) => {
        setFormData((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    useEffect(() => {
        const allRequiredFilled = combinedFields.every(
            (field) => formData[field.fieldName],
        );
        setCanSubmit(allRequiredFilled && !!selectedFile);
    }, [formData, selectedFile, combinedFields]);

    const handleUpload = async () => {
        if (selectedFile) {
            setSelectedFile(null);
            initialiseFormData();

            const fileFormat = selectedFile.name
                .split('.')
                .pop()
                ?.toLowerCase() as FileFormat;
            let properties:
                | DutchAssetProperties
                | BelgiumAssetProperties
                | FrenchAssetProperties;
            switch (market) {
                case Market.NETHERLANDS:
                    properties = {
                        asset_name: formData.asset_name,
                        market: market,
                        brand: formData.brand as Brand,
                        alcohol_strength:
                            formData.alcohol_strength as AlcoholStrength,
                    };
                    break;
                case Market.BELUX:
                    properties = {
                        asset_name: formData.asset_name,
                        market: market,
                        brand: formData.brand as Brand,
                        on_public_roads: formData.on_public_roads as YesNo,
                    };
                    break;
                case Market.FRANCE:
                    properties = {
                        asset_name: formData.asset_name,
                        market: market,
                        brand: formData.brand as Brand,
                    };
                    break;
            }

            if (
                fileFormat &&
                Object.values(FileFormat).includes(fileFormat) &&
                fileFormat !== FileFormat.PDF
            ) {
                const { url, unique_id } = await fetchUploadToken(
                    fileFormat,
                    formData.asset_name,
                );
                triggerFetch();
                await axios.put(url, selectedFile, {
                    headers: { 'x-ms-blob-type': 'BlockBlob' },
                });

                reviewAsset(unique_id, {
                    file_config: { file_format: fileFormat },
                    properties: properties,
                });
            }
        }
    };

    return (
        <ModalBase onClose={() => setHashValue('')} width="500px">
            <div className="upload-components">
                <h2 className="upload-title">Review New Asset</h2>
                <p style={{ textAlign: 'center' }}>
                    Upload your asset as an image and fill out the required
                    fields.
                </p>
                <UploadBoxAsset
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                />
                {combinedFields.map((field) => (
                    <div key={field.fieldName}>
                        <InputFieldTitle title={field.label} required={true} />
                        {field.inputType === 'text' ? (
                            <input
                                type="text"
                                className="user-input-text"
                                value={formData[field.fieldName] || ''}
                                onChange={(e) =>
                                    handleInputChange(
                                        field.fieldName,
                                        e.target.value,
                                    )
                                }
                            />
                        ) : field.inputType === 'dropdown' && field.options ? (
                            <select
                                className="user-input-dropdown"
                                value={formData[field.fieldName] || ''}
                                onChange={(e) =>
                                    handleInputChange(
                                        field.fieldName,
                                        e.target.value,
                                    )
                                }
                            >
                                <option value="" disabled>
                                    -- Select an option --
                                </option>
                                {field.options.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        ) : null}
                    </div>
                ))}
                <SignedIn>
                    <button
                        className="submit-button-upload"
                        onClick={handleUpload}
                        disabled={!canSubmit}
                    >
                        Upload
                    </button>
                </SignedIn>
                <SignedOut>
                    <SignInButton>
                        <button
                            className="submit-button-upload"
                            disabled={!canSubmit}
                        >
                            Upload
                        </button>
                    </SignInButton>
                </SignedOut>
            </div>
        </ModalBase>
    );
};

export default UploadAsset;
